import { useState } from "react"
import User from "./User/Index"
import Crendential from "./Credential/Index"

const Administration = () => {

    const [adminState, setAdminState] = useState({
        showUsersPage: true,
    })

    const handleShowUserPage = () => {
        setAdminState((prev) => ({
            ...prev,
            showUsersPage: true,
        }))
    }

    const handleShowCredentialPage = () => {
        setAdminState((prev) => ({
            ...prev,
            showUsersPage: false,
        }))
    }

    return (
        <div className="py-2">
            <div className="mt-5 ms-5">
                <button
                    type="button"
                    className={`btn ${adminState.showUsersPage && 'btn-primary'}`}
                    onClick={handleShowUserPage}
                >
                    Users
                </button>
                <button
                    type="button"
                    className={`btn ${!adminState.showUsersPage && 'btn-primary'}`}
                    onClick={handleShowCredentialPage}
                >
                    Credentials
                </button>
            </div>
            {adminState.showUsersPage && <User />}
            {!adminState.showUsersPage && <Crendential />}
        </div>
    )
}
export default Administration