import { useNavigate } from "react-router-dom"
import logo from '../../Images/logo-365build.png'

const Navbar = () => {

    const navigate = useNavigate()
    const pageUrl = window.location.hash

    const logout = () => {
        localStorage.removeItem('authToken')
        navigate('/login')
    }

    return (
        <nav className="navbar-expand-lg border-bottom sticky-top bg-light py-2 px-2">
            <div className="d-flex justify-content-between">
                <div className="navbar-brand">
                    <div className="d-flex align-items-center">
                        <img src={logo} width="60" height="50" alt="logo" />
                        <h3 className="text-muted ms-4 fs-2 pt-2">EWaybill</h3>
                    </div>
                </div>
                {pageUrl === "#/otp" &&
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary">OTP</button>
                        <button className="btn text-primary" onClick={logout}>Logout</button>
                    </div>
                }
            </div>
        </nav>
    )
}
export default Navbar