import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export const RequireAuth = ({ children, page }) => {
    
    const authEwb = localStorage.getItem('authToken')
    const authOtp = localStorage.getItem("authToken-otp")
    const location = useLocation()
    const auth = page === "otp" ? authOtp : authEwb

    if (!auth) {
        // if user not authenticated then send to login page
        return <Navigate
            to='/'
            replace
            state={{
                data: location.pathname
            }} />
    }

    return children
}

