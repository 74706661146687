import {useEffect, useState} from "react"
import {format} from 'date-fns'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faCircleInfo} from "@fortawesome/free-solid-svg-icons"
import {utils, writeFileXLSX} from 'xlsx'
import {ThreePulseCircles} from "../../Animation/Animation"
import ReactPaginate from "react-paginate"
import {DownloadButton} from "../../Common/Button"
import Form from "./Components/Form"

const Country = () => {

    const [vatState, setVatState] = useState({
        country: "",
        trns: [],
        currentTime: new Date(),
        response: "",
        inProcess: false
    })
    const [requests, setRequests] = useState([])
    const [requestsFromLS, setRequestFromLS] = useState(JSON.parse(localStorage.getItem("VAT")) || [])
    const formattedTime = format(vatState.currentTime, 'dd/MM hh:mm:ss a')
    const pageUrl = window.location.hash

    const handleState = (event) => {
        const {name, value} = event.target
        setVatState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleValidate = async () => {
        try {
            const payload = {
                trns: [...new Set((vatState.trns.split('\n').filter(item => item !== '')))]
            }
            if (payload) {
                const state = vatState.country
                const url = `https://validate-vat.e-waybill.in/api/vat/validate2?state=${state}`
                const startTime = new Date().getTime()
                const startTimeStr = new Date().toLocaleString('en-IN')
                const response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(payload)
                })
                let r = await response.json()
                r = {...r, state, startTime, startTimeStr}

                setVatState(prevState => ({
                    ...prevState,
                    response: r,
                    inProcess: r.queueSize !== 0 || r.completed !== r.outOf || r.results.length === 0,
                    startTime,
                    startTimeStr
                }))
            }
        } catch (e) {
            console.error("error occured during validation", e)
        }
    }

    const handleCheckStatus = async () => {
        try {
            if (vatState.inProcess && vatState.response.requestId !== "" && vatState.response.requestId !== undefined) {
                const state = vatState.country
                const url = `https://validate-vat.e-waybill.in/api/vat/status2?request_id=${vatState.response.requestId}&state=${state}`
                const response = await fetch(url)
                let r = await response.json()
                const endTime = new Date().getTime()
                const endTimeStr = new Date().toLocaleString('en-IN')
                const {startTime, startTimeStr} = vatState
                const inProcess = r.queueSize !== 0 || r.completed !== r.outOf || r.results.length === 0
                r = {...r, state, inProcess, startTime, startTimeStr, endTime, endTimeStr}

                setVatState(prevState => ({
                    ...prevState,
                    response: r,
                    inProcess: r.queueSize !== 0 || r.completed !== r.outOf || r.results.length === 0
                }))

                setRequests(prev => {
                    const existingIndex = prev.findIndex(item => item.r.requestId === r.requestId)
                    if (existingIndex !== -1) {
                        // Update the existing entry
                        const updatedRequests = [...prev]
                        updatedRequests[existingIndex] = {r}
                        return updatedRequests
                    } else {
                        // Add as a new entry
                        return [...prev, {r}]
                    }
                })
                localStorage.setItem("VAT", JSON.stringify(requests))
                setRequestFromLS(JSON.parse(localStorage.getItem("VAT")))
            }
        } catch (e) {
            console.error("Unable to check status", e)
        }
    }

    const handleStopAndDeleteProcess = async (requestId, state) => {
        try {
            const url = `https://validate-vat.e-waybill.in/api/vat?request_id=${requestId}&state=${state}`
            await fetch(url, {
                method: "DELETE"
            })
            const updatedData = requests.filter(item => item.r.requestId !== requestId)
            setRequests(updatedData)
            localStorage.setItem("VAT", JSON.stringify(updatedData))
            setRequestFromLS(JSON.parse(localStorage.getItem("VAT")))
            console.log("process stopped and deleted")
        } catch (e) {
            console.error("Error in stopping process", e)
        }
    }

    const handleDownloadExcel = (requestId) => {
        const eachRequest = requestsFromLS.find(item => item.r.requestId === requestId)
        const newArray = eachRequest.r.results?.map(({trn, status, names}) => ({
            trn,
            status,
            "english name": names[0].english,
            "arabic name": names[0].arabic
        }))
        const date = format(new Date(), 'yyyy-MM-dd-HH-mm')
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `${eachRequest.r.state}_TRN_${date}.xlsx`)
    }

    const PER_PAGE = 5
    const [currentPage, setCurrentPage] = useState(0)

    function handlePageClick({selected: selectedPage}) {
        setCurrentPage(selectedPage)
    }

    const offset = currentPage * PER_PAGE
    const pageCount = Math.ceil(requestsFromLS.length / PER_PAGE)

    useEffect(() => {
        localStorage.setItem("VAT", JSON.stringify(requests))
        setRequestFromLS([...requests])
    }, [requests])

    useEffect(() => {
        const country = pageUrl.split('/').slice(-1)[0].toUpperCase()
        setVatState((prev) => ({
            ...prev,
            country: country
        }))
    }, [pageUrl])

    useEffect(() => {
        const interval = setInterval(() => {
            setVatState((prev) => ({
                ...prev,
                currentTime: new Date()
            }))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const interval = setInterval(async () => {
            await handleCheckStatus()
        }, 5000)
        return () => clearInterval(interval)
    })

    return (
        <div className="row p-2 mx-0 mt-4">
            <Form
                handleState={handleState}
                handleValidate={handleValidate}
                vatState={vatState}
            />
            <div className="col-8">
                <div className="row justify-content-between" style={{height: "fit-content"}}>
                    <div className="alert alert-warning col me-2 align-content-center text-center">
                        2 Jobs will be run at a time, if there are more than 2 jobs then it will wait in queue, please
                        wait..
                    </div>
                    <div className="alert alert-primary col align-content-center text-center">
                        System Time : {formattedTime}
                    </div>
                </div>
                {
                    requestsFromLS
                        .sort((a, b) => b.r.startTime - a.r.startTime)
                        .slice(offset, offset + PER_PAGE)
                        .map((item, index) => {
                            return (
                                <div className="row card mb-2" key={index + offset}>
                                    <div className="d-flex card-header justify-content-between">
                                        <div>
                                            {item.r.state} - {item.r.requestId}
                                        </div>
                                        {item.r.inProcess &&
                                            <div className="text-success">
                                                Queue {item.r.queuePosition} of {item.r.queueSize}
                                            </div>}
                                        <div>
                                            Start: {item.r.startTimeStr}
                                        </div>
                                        {!item.r.inProcess &&
                                            <div>
                                                End: {item.r.endTimeStr}
                                            </div>
                                        }
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-2">
                                            {item.r.inProcess ? (
                                                <>
                                                    <ThreePulseCircles/>
                                                    <span className="ms-2">Processing</span>
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faCheck} className="me-2"/>
                                                    <span>Completed</span>
                                                </>
                                            )}
                                            <span className="text-success ms-2">{item.r.completed}</span>
                                            <span className="mx-1">/</span>
                                            <span className="text-info">{item.r.outOf}</span>
                                        </div>
                                        {!item.r.inProcess && (
                                            <div className="alert alert-info">
                                                <FontAwesomeIcon icon={faCircleInfo}/> Kindly download the Excel result
                                                now
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex card-header justify-content-end border border-top">
                                        <button
                                            type="button"
                                            className="me-2 btn btn-danger"
                                            onClick={() => handleStopAndDeleteProcess(item.r.requestId, item.r.state)}
                                        >
                                            Stop & Remove
                                        </button>
                                        <DownloadButton
                                            onClick={() => handleDownloadExcel(item.r.requestId)}
                                            disabled={item.r.results.length === 0}
                                        >
                                            Excel Download
                                        </DownloadButton>
                                    </div>
                                </div>
                            )
                        })
                }
                {requestsFromLS.length !== 0 && <div className="mb-5 pb-2">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active primary"}
                    />
                </div>}
            </div>
        </div>
    )
}
export default Country