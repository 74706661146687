import { useEffect, useState } from "react"
import TimerSlider from "../Common/TimerSlider"
import { getUserCredentials } from "../../API/GetUserCreds"

let jsotp = require('jsotp')

const Otp = () => {
    const authToken = localStorage.getItem('authToken-otp')
    const [users, setUsers] = useState([])

    useEffect(() => {

        getUserCredentials(authToken).then(u => setUsers(u));

        const intervalId = setInterval(() => {
            setUsers(users => {
                return users.map(u => {
                    let totp = jsotp.TOTP(u.totpKey, 60).now()
                    let seconds = (60 - (new Date()).getSeconds())
                    const sliderWidth = `${(seconds / 60) * 100}%`

                    return {
                        ...u,
                        otp: totp,
                        seconds: seconds,
                        sliderWidth: sliderWidth
                    }
                })
            })

        }, 1000);
        return () => clearInterval(intervalId);
    });

    return (
        <div>
            <div className="d-flex flex-wrap justify-content-center my-5">
                {users?.map((item, index) => {
                    return (
                        <div className="card me-2 mb-2" key={index}>
                            <div className="card-header">OTP for <span className="ms-5">{item.userName}/{item.gstNumber}</span></div>
                            <div className="card-body">
                                <div className="row pb-4">
                                    <div className="col-6">OTP</div>
                                    <div className="col-6 fs-1">{item.otp}</div>
                                </div>
                                <div className="row pb-4">
                                    <div className="col-6">Valid for</div>
                                    <div className="col-6">
                                        <TimerSlider sliderWidth={item.sliderWidth} />
                                        <div>{item.seconds} Seconds</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Otp;