import { useState, useContext, useEffect } from "react"
import { Actions } from "../../../Reducer/reducer"
import { CredentialsContext } from "../../../API/CredentialsContext"
import Wrapper from "../../../Common/Wrapper"
import Table from "./Table"
import Form from "./Form"

const Upload = () => {

    const [uploadState, setUploadState] = useState({
        file: {},
        credential: {},
        uploadedFileData: []
    })
    const { state, dispatch } = useContext(CredentialsContext)
    const authToken = localStorage.getItem('authToken')
    const users = state?.credentials || []
    const isUploadButtonDisabled =
        Object.keys(uploadState.credential).length === 0 ||
        document.getElementById("file").value === ""

    const handleState = (event) => {
        const { name, value, files } = event.target
        if (name === 'credential') {
            setUploadState(prevState => ({
                ...prevState,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setUploadState(prevState => ({
                ...prevState,
                [name]: files[0]
            }))
        }
    }

    const handleFileResponse = async () => {
        const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken,
            },
        })
        const data = await response.json()
        setUploadState((prev) => ({ ...prev, uploadedFileData: data }))
    }

    const handleFileUpload = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })

            if (!uploadState.file) {
                alert("Upload File")
                return
            }

            const formData = new FormData()
            formData.append('file', uploadState.file)
            formData.append('gstin', uploadState.credential.gstNumber)

            const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
                method: 'POST',
                headers: {
                    'X-Auth-Token': authToken,
                },
                body: formData
            })

            if (response.ok) {
                console.log("File uploaded successfully")
                handleFileResponse()
            } else {
                console.error("Error in getting response from api")
            }
        }
        catch (error) { console.error('Error in uploading file:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    useEffect(() => {
        const handleFileResponse = async () => {
            const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                },
            })
            const data = await response.json()
            setUploadState((prev) => ({ ...prev, uploadedFileData: data }))
        }
        handleFileResponse()
    }, [authToken])

    return (
        <Wrapper>
            <Form
                handleFileUpload={handleFileUpload}
                handleState={handleState}
                users={users}
                isUploadButtonDisabled={isUploadButtonDisabled}
            />
            <Table uploadState={uploadState} />
        </Wrapper>
    )
}
export default Upload