import { ThreePulseCircles } from "../../../Animation/Animation"
import { DownloadButton, PrimaryNormalButton } from "../../../Common/Button"

const SubTable = ({ requestInDetail, handleDownloadExcel }) => {
    return (
        <div className="row">
            <h4>Waybills for {requestInDetail?.requestId}</h4>
            <div className="overflow-x-auto mb-3">
                <table className="table table-sm">
                    <thead className="table-secondary">
                        <tr className="text-uppercase">
                            <th>Consignor</th>
                            <th>Consignee</th>
                            <th>Transporter</th>
                            <th>Invoice</th>
                            <th>Ewaybill</th>
                            <th>
                                <span className="btn-group">
                                    <PrimaryNormalButton>Print All</PrimaryNormalButton>
                                    <DownloadButton
                                        onClick={handleDownloadExcel}
                                    >
                                        Excel Download
                                    </DownloadButton>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requestInDetail?.request.ewaybills.map((item, index) => {
                                const Response = requestInDetail.response
                                const Consignor = item.consignor
                                const Consignee = item.consignee
                                const Invoice = item.invoice
                                const LineItems = item.lineItems
                                return (
                                    <tr key={index}>
                                        <td>
                                            {Consignor.name} <br />
                                            {Consignor.pinCode} <br />
                                            {Consignor.gstNumber}
                                        </td>
                                        <td>
                                            {Consignee.name} <br />
                                            {Consignee.pinCode} <br />
                                            {Consignee.gstNumber}
                                        </td>
                                        <td>
                                            {item.transporter.id}
                                        </td>
                                        <td>
                                            # {Invoice.invoiceNo} <br />
                                            {Invoice.invoiceDt} <br />
                                            Rs {Invoice.invoiceVal} <br />
                                            {
                                                LineItems.map((item, index) =>
                                                    <div key={index}>{item.hsnCode} - {item.qty} {item.unit}</div>
                                                )
                                            }
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "darkgreen",
                                                    boxShadow: "1px 2px 3px #333333",
                                                    padding: "10px",
                                                    marginTop: "20px"
                                                }}>
                                                {Response.generatedEWaybills[Invoice.invoiceNo]}
                                            </div>
                                        </td>
                                        <td className="text-danger">
                                            {
                                                requestInDetail.response.status !== "COMPLETED" ?
                                                    <ThreePulseCircles /> : Response.errors[Invoice.invoiceNo]
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default SubTable