import { useState, useContext } from "react"
import { Actions } from "../../../Reducer/reducer"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { utils, writeFileXLSX } from 'xlsx'
import Table from "./Table"
import Wrapper from "../../../Common/Wrapper"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"
import { DownloadButton, PrimarySubmitButton } from "../../../Common/Button"

const Reports = () => {

    const [rsState, setRsState] = useState({
        date: "",
        credential: {},
        keyword: "",
        showError: false,
        reportsData: []
    })
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const SuccessCount = rsState.reportsData?.filter((item) => item.newValidity !== null).length
    const ErrorCount = rsState.reportsData?.filter((item) => item.newValidity === null).length
    const isGetDataButtonDisabled =
        rsState.date === "" ||
        Object.keys(rsState.credential).length === 0 ||
        state.loading

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setRsState(prev => ({
                ...prev,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setRsState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleGetData = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const authToken = localStorage.getItem('authToken')
            const url = ` https://app.e-waybill.in/web-app/job-result?gstin=${rsState.credential.gstNumber}&date=${rsState.date}`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken
                }
            })
            const data = await response.json()
            setRsState((prev) => ({ ...prev, reportsData: data }))
        }
        catch (error) { console.error('Error in getting data:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const handleShowAllReports = () => {
        setRsState((prev) => ({ ...prev, showError: false }))
    }

    const handleShowOnlyErrorReports = () => {
        setRsState((prev) => ({ ...prev, showError: true }))
    }

    const handleDownloadExcel = () => {
        const newArray = rsState.reportsData.map(({
            ewb,
            oldValidity,
            newValidity,
            errorMsg
        }) => ({
            EWB: ewb,
            OLD_VALIDITY: oldValidity,
            NEW_VALIDITY: newValidity,
            ERROR: errorMsg || (!newValidity && "ERROR") || ""
        }))
        const date = new Date().toLocaleString()
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `AutoExtend_${rsState.credential.gstNumber}_${date}.xlsx`)
    }

    return (
        <Wrapper>
            <form className="col-lg-3 m-3" onSubmit={handleGetData}>
                <h4>Reports</h4>
                <FormFieldWrapper>
                    <label htmlFor="credential" className="mb-2">Credential</label>
                    <select
                        className="form-select"
                        id="credential"
                        name="credential"
                        onChange={handleState}
                    >
                        <option>Select</option>
                        {users.map((user, index) => {
                            return (
                                <option key={index} value={user.id}>{user.gstNumber}</option>
                            )
                        })}
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label className="mb-2" htmlFor="date">Select Date</label>
                    <input
                        className="form-control"
                        id="date"
                        name="date"
                        type="date"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <PrimarySubmitButton
                    disabled={isGetDataButtonDisabled}
                >
                    Get Data
                </PrimarySubmitButton>
            </form>
            <div className="col-lg-7 m-5">
                {rsState.credential.gstNumber && rsState.date && <h4>
                    Auto Extend Report For <span className="text-primary">
                        {rsState.credential.gstNumber} </span> on <span className="text-success">
                        {rsState.date}</span>
                </h4>}
                <div className="d-flex justify-content-between flex-wrap my-3">
                    <div className="d-flex">
                        <input
                            className="form-control"
                            placeholder="Search EWB Number"
                            id='keyword'
                            name='keyword'
                            onChange={handleState}
                        />
                    </div>
                    <div className="text-info my-2">Total Items: {rsState.reportsData.length}</div>
                    <div className="text-success my-2">Success: {SuccessCount}</div>
                    <div className="text-danger my-2">Errors: {ErrorCount}</div>
                    <div className="btn-group my-2">
                        <button
                            className="btn btn-primary"
                            onClick={handleShowAllReports}
                            type="button">
                            Show All
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={handleShowOnlyErrorReports}
                            type="button"
                        >
                            Show Only Error Reports
                        </button>
                        <DownloadButton
                            onClick={handleDownloadExcel}>
                            Download All in Excel
                        </DownloadButton>
                    </div>
                </div>
                <Table rsState={rsState} />
            </div>
        </Wrapper>
    )
}
export default Reports