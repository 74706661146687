import { useEffect, useRef } from "react";
import loading from './loading.svg'

export const LoadingCircle = () => {
    const ref = useRef()
    useEffect(() => {
        const a = ref?.current
        a.showModal()
        return () => {
            a.close()
        }
    })
    return (
        <dialog ref={ref} className={"loading-dialog"}>
            <img src={loading} alt="loading..." />
        </dialog>
    )
}

export const ThreePulseCircles = ({ bgcolor }) => {
    let backgroundColor = bgcolor
    if (backgroundColor === "" || backgroundColor === undefined) {
        backgroundColor = "bg-black"
    }
    return (
        <div className="spinner">
            <div className={`bounce1 ${backgroundColor}`}></div>
            <div className={`bounce2 ${backgroundColor}`}></div>
            <div className={`bounce3 ${backgroundColor}`}></div>
        </div>
    )
}