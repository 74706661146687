import React, { useEffect, useState, useContext } from "react" // eslint-disable-line import/first
import { CredentialsContext } from "../../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import { Actions } from "../../../Reducer/reducer"
import Table from "./Table"
import Wrapper from "../../../Common/Wrapper"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"
import { FormCheckWrapper } from "../../Admin/Credential/Index"

const RTO = () => {
    const [requests, setRequests] = useState([])
    const [rtoState, setRtoState] = useState({
        credential: {},
        transporterId: "",
        ewaybillNos: "",
        returnReason: "Party Unavailable",
        documentType: "OTH",
        ConCon: "reverse"
    })
    const [checkbox, setCheckbox] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isGenerateButtonDisabled =
        Object.keys(rtoState.credential).length === 0 ||
        rtoState.documentType === "" ||
        rtoState.ewaybillNos === "" ||
        rtoState.returnReason === "" ||
        rtoState.transporterId === "" ||
        state.loading ||
        (
            requests[0]?.status !== undefined &&
            requests[0]?.status !== "COMPLETED"
        )

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setRtoState(prev => ({
                ...prev,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setRtoState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleCheckbox = (event) => {
        const index = checkbox.indexOf(event.target.value)
        if (index === -1) {
            setCheckbox([...checkbox, event.target.value])
        } else {
            setCheckbox(checkbox.filter((checkBox) => checkBox !== event.target.value))
        }
    }

    const handleGenerateRTO = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: rtoState.credential.userName,
                    password: rtoState.credential.password,
                    gstNumber: rtoState.credential.gstNumber
                },
                ewaybillNos: rtoState.ewaybillNos.split("\n").map(x => x.trim()).filter(x => x.length === 12),
                genPartB: checkbox.includes("genPartB"),
                taxInclusive: checkbox.includes("taxInclusive"),
                documentType: rtoState.documentType,
                transporterId: rtoState.transporterId,
                returnReason: rtoState.returnReason
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const duplicate = rtoState.ConCon === "constant"
            const url = `https://api.e-waybill.in/api/e-waybill/rto?sync=false&duplicate=${duplicate}`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...payload, requestId })
            })
            if (response.ok) {
                const data = await response.json()
                setRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
            } else {
                console.error("Error in getting response")
            }
        }
        catch (error) { console.error('Error in getting response from Index EWB api:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://api.e-waybill.in/api/e-waybill/rto-status?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'COMPLETED') {
                        return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            } catch (error) {
                console.error('Error in checking status of Index EWB api:', error)
            } finally {
                dispatch({ type: Actions.HideLoading })
            }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <Wrapper>
            <form className="col-lg-3 m-3" onSubmit={handleGenerateRTO}>
                <h4>Generate RTO E-Waybill</h4>
                <FormFieldWrapper>
                    <label htmlFor="credential" className="mb-2">Credential</label>
                    <select
                        className="form-select"
                        id="credential"
                        name="credential"
                        onChange={handleState}
                    >
                        <option>Select</option>
                        {users.map((user, index) => {
                            return (
                                <option key={index} value={user.id}>{user.gstNumber}</option>
                            )
                        })}
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="transporterId" className="mb-2">Transporter ID</label>
                    <input
                        className="form-control"
                        name="transporterId"
                        id="transporterId"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="ewaybillNos" className="mb-2">Forward EWB Nos</label>
                    <textarea
                        className="form-control"
                        style={{ height: "200px" }}
                        name="ewaybillNos"
                        id="ewaybillNos"
                        placeholder="Enter Waybill Nos, One Per Line. Max limit 50" onChange={handleState}
                        maxLength={800}
                    />
                </FormFieldWrapper>
                <FormCheckWrapper>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="taxInclusive"
                        id="taxInclusive"
                        value="taxInclusive"
                        onChange={handleCheckbox}
                    />
                    <label htmlFor="taxInclusive" className="mb-2">Tax Inclusive?</label>
                </FormCheckWrapper>
                <FormCheckWrapper>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="genPartB"
                        id="genPartB"
                        value="genPartB"
                        onChange={handleCheckbox}
                    />
                    <label htmlFor="genPartB" className="mb-2">Make Part-B?</label>
                </FormCheckWrapper>
                <FormFieldWrapper>
                    <label htmlFor="returnReason" className="mb-2">Return Reason</label>
                    <input
                        className="form-control"
                        name="returnReason"
                        id="returnReason"
                        onChange={handleState}
                        value={rtoState.returnReason}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="documentType" className="mb-2">Document Type</label>
                    <select
                        className="form-select"
                        name="documentType"
                        id="documentType"
                        onChange={handleState}
                    >
                        <option value="OTH">OTHERS</option>
                        <option value="DC">CHALLAN</option>
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="ConCon" className="mb-2">RTO/Duplicate</label>
                    <select
                        className="form-select"
                        name="ConCon"
                        id="ConCon"
                        onChange={handleState}
                    >
                        <option defaultValue value="reverse">Reverse Consignor & Consignee</option>
                        <option value="constant">Keep Consignor & Consignee Same</option>
                    </select>
                </FormFieldWrapper>
                <button
                    className="btn btn-primary mb-5"
                    disabled={isGenerateButtonDisabled}
                    type="submit"
                >
                    Generate RTO
                </button>
            </form>
            <div className="col-lg-7 m-5">
                <div className="row">
                    <h4>RTO Forms</h4>
                    <div className="overflow-x-auto mb-3">
                        <Table requests={requests} />
                    </div>
                </div>
            </div>
        </Wrapper >
    )
}
export default RTO