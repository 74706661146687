import { PrimarySubmitButton } from "../../../Common/Button"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"

const Form = ({ handleFileUpload, handleState, users, isUploadButtonDisabled }) => {
    return (
        <form className="col-lg-3 m-3" onSubmit={handleFileUpload}>
            <h4>Upload Today's File</h4>
            <FormFieldWrapper>
                <label htmlFor="credential" className="mb-2">Credential</label>
                <select
                    className="form-select"
                    id="credential"
                    name="credential"
                    onChange={handleState}
                >
                    <option>Select</option>
                    {
                        users.map((user, index) => {
                            return (
                                <option key={index} value={user.id}>{user.gstNumber}</option>
                            )
                        })
                    }
                </select>
            </FormFieldWrapper>
            <div className="form-group my-3">
                <label className="mb-2" htmlFor="gstin">Choose File To Upload</label>
                <input
                    className="form-control"
                    id="file"
                    name="file"
                    type="file"
                    onChange={handleState}
                />
            </div>
            <PrimarySubmitButton
                disabled={isUploadButtonDisabled}
            >
                Upload File
            </PrimarySubmitButton>
        </form>
    )
}
export default Form