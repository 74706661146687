import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGooglePlusG } from "@fortawesome/free-brands-svg-icons"
import { LoadingCircle } from "../Animation/Animation"

const LoginUI = ({ handleLoginWithCredentials, loginState, handleKeyDown, handleState, handleGoogleLogin, isButtonDisabled }) => {
    
    const pageUrl = window.location.hash

    return (
        <form className="d-flex justify-content-center my-5" onSubmit={handleLoginWithCredentials}>
            <div className="col-6">
                <h4 className="text-start row pb-4">Login</h4>
                <div className="form-group row justify-content-start pb-3">
                    <label className="text-start p-0 pb-2">User Name</label>
                    <input type="text" className="form-control" name="userName" id="userName" value={loginState.userName}
                        onChange={handleState} placeholder="Enter your UserName" />
                </div>
                <div className="form-group row justify-content-start pb-3">
                    <label className="text-start p-0 pb-2">Password</label>
                    <input type="password" className="form-control" name="password" id="password" value={loginState.password}
                        onChange={handleState} placeholder="Enter your Password" onKeyDown={handleKeyDown} />
                </div>
                {
                    loginState.errorMessage !== "" &&
                    <div className="row justify-content-start alert alert-danger">
                        <div>{loginState.errorMessage}</div>
                    </div>}
                <div className="row justify-content-start">
                    <div className="form-group text-start p-0">
                        <button className="btn btn-primary me-1" type="submit" disabled={isButtonDisabled}>Login</button>
                        <button className="btn btn-danger" type="button" onClick={() => handleGoogleLogin()}>
                            <FontAwesomeIcon icon={faGooglePlusG} /> Login
                        </button>
                    </div>
                </div>
                {(pageUrl === "#/" || pageUrl === "") && <div className="row border justify-content-start mt-4 mb-5 alert alert-primary" role="alert">
                    <div>
                        This is a new version of the app.
                        Incase of any issues inform customer care (The email and contact details are given below) or temporarily use the old app at
                        <a href="https://app-v1.e-waybill.in" className="ms-1">https://app-v1.e-waybill.in</a>
                    </div>
                </div>}
            </div>
            {pageUrl === "#/login" && loginState.loading && <LoadingCircle />}
        </form>
    )
}
export default LoginUI