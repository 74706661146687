import {ThreePulseCircles} from "../../../Animation/Animation";

const Table = ({data}) => {
    return (
        <table className="table table-borderless table-striped">
            <thead>
            <tr className="text-uppercase table-dark">
                <th>From PIN</th>
                <th>To PIN</th>
                <th>Distance (in KM)</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.pincode1}</td>
                            <td>{item.pincode2}</td>
                            <td>{
                                item.pending ? <ThreePulseCircles /> : item.distance
                            }</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )
}

export default Table