import classNames from "classnames"
import { ThreePulseCircles } from "../../../Animation/Animation"

const BottomTable = ({ modalRequests }) => {
    return (
        <table className="table">
            <thead>
                <tr className="text-uppercase">
                    <th>EWB</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    modalRequests[0]?.response.ewaybillNos.map((ewbNo, index) => {
                        const success = modalRequests[0].response.validity
                        const errors = modalRequests[0].response.errors
                        return (
                            <tr key={index} >
                                <td>{ewbNo}</td>
                                <td className={classNames({
                                    "text-success": success[ewbNo],
                                    "text-danger": errors[ewbNo]
                                })}>
                                    {success[ewbNo] || errors[ewbNo]}
                                </td>
                                <td>{modalRequests[0].response.status !== "COMPLETED" && <ThreePulseCircles />}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
export default BottomTable