import { useContext, useEffect, useState } from "react"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import Table from "./Table"
import { Actions } from "../../../Reducer/reducer"
import Wrapper from "../../../Common/Wrapper"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"

const Index = () => {
    const [extendState, setExtendState] = useState({
        credential: {},
        currentPincode: "",
        wareHousePlace1: "",
        wareHousePlace2: "",
        wareHousePlace3: "",
        ewaybillNos: ""
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isExtendButtonDisabled =
        extendState.currentPincode === "" ||
        extendState.wareHousePlace1 === "" ||
        extendState.wareHousePlace2 === "" ||
        extendState.wareHousePlace3 === "" ||
        extendState.ewaybillNos === "" ||
        Object.keys(extendState.credential).length === 0 ||
        state.loading ||
        requests[0]?.status === 400

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setExtendState(prev => (
                {
                    ...prev,
                    [name]: users.find(u => u.id === +value)
                })
            )
        } else {
            setExtendState(prev => (
                {
                    ...prev,
                    [name]: value
                })
            )
        }
    }

    const handleExtendEWBInWarehouse = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                alwaysGiveValidity: true,
                credentials: {
                    userName: extendState.credential?.userName,
                    password: extendState.credential?.password,
                    gstNumber: extendState.credential?.gstNumber,
                },
                currentPincode: extendState.currentPincode,
                currentStatus: "IN_WAREHOUSE",
                curretPlace: extendState.wareHousePlace1,
                wareHousePlace1: extendState.wareHousePlace1,
                wareHousePlace2: extendState.wareHousePlace2,
                wareHousePlace3: extendState.wareHousePlace3,
                ewaybillNos: extendState.ewaybillNos.split("\n")
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://prod-1.e-waybill.in/api/v2/extend',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId }),
                })
            const data = await response.json()
            setRequests([{
                requestId, status: data.status, response: data, request: payload, checkCount: 0
            }])
        }
        catch (error) { console.error('Error in getting response from extend EWB in warehouse api:', error) }
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://prod-1.e-waybill.in/api/e-waybill/extend-status?request_id=${r.requestId}`)
                const data = await response.json()
                if (data.status === 'COMPLETED') {
                    return { ...r, status: 'COMPLETED', response: data }
                } else {
                    return { ...r, status: data.status }
                }
            }
            catch (error) { console.error('Error in checking status of extend EWB api:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <Wrapper>
            <form className="col-lg-3 m-3" onSubmit={handleExtendEWBInWarehouse}>
                <h4>Extend EWaybills</h4>
                <FormFieldWrapper>
                    <label htmlFor='credential' className="mb-2">Credential</label>
                    <select className="form-select" id="credential" name="credential" onChange={handleState}>
                        <option>Select</option>
                        {
                            users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })
                        }
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="wareHousePlace1" className="mb-2">Warehouse Addr - 1 (Required)</label>
                    <input
                        className="form-control"
                        type="text"
                        name="wareHousePlace1"
                        id="wareHousePlace1"
                        placeholder="Enter Warehouse Address"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="wareHousePlace2" className="mb-2">Warehouse Addr - 2 (Required)</label>
                    <input
                        className="form-control"
                        type="text"
                        name="wareHousePlace2"
                        id="wareHousePlace2"
                        placeholder="Enter Warehouse Address"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="wareHousePlace3" className="mb-2">Warehouse Addr - 3 (Required)</label>
                    <input
                        className="form-control"
                        type="text"
                        name="wareHousePlace3"
                        id="wareHousePlace3"
                        placeholder="Enter Warehouse Address"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="currentPincode" className="mb-2">Warehouse PIN (Required)</label>
                    <input
                        className="form-control"
                        type="text"
                        name="currentPincode"
                        id="currentPincode"
                        placeholder="Enter Warehouse PIN"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="ewaybillNos" className="mb-2">Waybill Nos</label>
                    <textarea
                        className="form-control"
                        style={{ height: "200px" }}
                        type="text"
                        name="ewaybillNos"
                        id="ewaybillNos"
                        placeholder="Enter Waybill Nos, One Per Line"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <button
                    className="btn btn-primary mb-5"
                    disabled={isExtendButtonDisabled}
                    type="submit"
                >
                    Extend
                </button>
            </form>
            <div className="col-lg-7 m-5">
                <Table requests={requests} />
            </div>
        </Wrapper>
    )
}
export default Index