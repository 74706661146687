import FormFieldWrapper from "../../../Common/FormFieldWrapper"
import { PrimaryNormalButton } from "../../../Common/Button"
import { ThreePulseCircles } from "../../../Animation/Animation"

const Form = ({ handleState, handleValidate, vatState }) => {

    const isValidateDisabled = vatState.inProcess || vatState.trns.length === 0

    return (
        <div className="col-4">
            <FormFieldWrapper>
                <label htmlFor='trns' className="mb-2">TRN (max 5000)</label>
                <textarea
                    className="form-control"
                    type="text"
                    name="trns"
                    id="trns"
                    placeholder="TRNs, One Per Line"
                    maxLength={5000}
                    rows={7}
                    onChange={handleState}
                />
            </FormFieldWrapper>
            <FormFieldWrapper>
                <label htmlFor='country' className="mb-2">State</label>
                <select
                    className="form-select"
                    type="text"
                    name="country"
                    id="country"
                    placeholder="TRNs, One Per Line"
                    onChange={handleState}
                    value={vatState.country}
                >
                    <option value="UAE">UAE</option>
                    <option value="KSA">KSA</option>
                    <option value="EGYPT">EGYPT</option>
                </select>
            </FormFieldWrapper>
            <FormFieldWrapper>
                <PrimaryNormalButton
                    onClick={handleValidate}
                    disabled={isValidateDisabled}
                >
                    {vatState.inProcess ? <ThreePulseCircles /> : "Validate"}
                </PrimaryNormalButton>
            </FormFieldWrapper>
        </div>
    )
}
export default Form