import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { ThreePulseCircles } from "../../Animation/Animation"
import { PrimaryNormalButton } from "../../Common/Button"
import TopTable from "./ExtendTables/Top"
import BottomTable from "./ExtendTables/Bottom"

const ExtendModal = ({ handleModalState, modalState, modalRequests, handleExtendEWB, clearModalRequests, isExtendButtonDisabled, isPulseLoadingVisibleForExtendButton }) => {
    return (
        <div className="modal fade" id="extendEWBModal" tabIndex="-1" aria-labelledby="extendEWBModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Enter Details</h3>
                    </div>
                    <div className="modal-body">
                        <TopTable modalState={modalState} handleModalState={handleModalState} />
                        <hr className="mt-5" />
                        <BottomTable modalRequests={modalRequests} />
                    </div>
                    <div className="modal-footer">
                        <PrimaryNormalButton
                            disabled={isExtendButtonDisabled}
                            onClick={handleExtendEWB}>
                            {
                                isPulseLoadingVisibleForExtendButton ?
                                    <ThreePulseCircles /> :
                                    <> <FontAwesomeIcon icon={faCheck} /> Extend</>
                            }
                        </PrimaryNormalButton>
                        <button
                            type="button"
                            className="btn btn-warning"
                            data-bs-dismiss="modal"
                            onClick={clearModalRequests}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExtendModal