const Table = ({uploadState}) => {
    return (
        <div className="col-lg-7 m-5">
            <h4>Upload History</h4>
            <table className="table table-borderless table-striped mt-4 mb-5">
                <thead>
                    <tr className="text-uppercase table-dark">
                        <th>SL.NO</th>
                        <th>File Name</th>
                        <th>Uploaded By</th>
                        <th>Time Uploaded</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadState.uploadedFileData.map((item, index) => {
                            const time = new Date(item.uploadedAt).toLocaleTimeString()
                            const date = new Date(item.uploadedAt).toDateString()
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.fileName}</td>
                                    <td>{item.uploadedBy}</td>
                                    <td>{time} {date}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="alert alert-danger my-2">
                NOTE: Upload must be done before 8pm
            </div>
        </div>
    )
}
export default Table