const Table = ({ handleDownloadExcel, requests }) => {
    return (
        <table className="table table-borderless table-striped mt-4 mb-5">
            <thead>
                <tr className=" text-uppercase table-dark">
                    <th>SL.NO</th>
                    <th>Old Ewaybill</th>
                    <th>New EWB</th>
                    <th>New Validity</th>
                    <th>
                        <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            onClick={handleDownloadExcel}>
                            Download
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    requests[0]?.response[1][0] !== '' ?
                        requests[0]?.response.slice(1, -1).map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item[0]}</td>
                                    <td>{item[1]}</td>
                                    <td>{item[2]}</td>
                                    <td></td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={5} className="text-danger text-center">
                                Invalid EWaybill !!
                            </td>
                        </tr>
                }
            </tbody>
        </table>
    )
}
export default Table