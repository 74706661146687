import logo from '../../Images/logo-365build.png'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <div className='d-flex justify-content-between border border-bottom border-dark pt-2 px-2'>
            <div className='d-flex'>
                <img src={logo}
                    width="60"
                    height="50"
                    alt="logo" />
                <h3 className='mx-2 text-muted justify-content-center align-content-center'>VAT Validate</h3>
            </div>
            <ul className='d-flex pt-2'>
                <li className="me-2 nav-link">
                    <NavLink
                        className="text-decoration-none btn border-0"
                        to='/vat/uae'
                        style={({ isActive }) => ({ color: isActive ? 'white' : 'rgb(13, 110, 253)', background: isActive && "rgb(13, 110, 253)" })}
                    >
                        VAT Validate (UAE)
                    </NavLink>
                </li>
                <li className="me-2 nav-link">
                    <NavLink
                        className="text-decoration-none btn border-0"
                        to='/vat/ksa'
                        style={({ isActive }) => ({ color: isActive ? 'white' : 'rgb(13, 110, 253)', background: isActive && "rgb(13, 110, 253)" })}
                    >
                        VAT Validate (KSA)
                    </NavLink>
                </li>
                <li className="me-2 nav-link">
                    <NavLink
                        className="text-decoration-none btn border-0"
                        to='/vat/egypt'
                        style={({ isActive }) => ({ color: isActive ? 'white' : 'rgb(13, 110, 253)', background: isActive && "rgb(13, 110, 253)" })}
                    >
                        VAT Validate (Egypt)
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navbar
