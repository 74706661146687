import { HashRouter, Routes, Route } from "react-router-dom"
import Footer from "../Common/Footer"
import Navbar from "./Common/Navbar"
import Country from "./Pages/Country"

const VatApp = () => {
    return (
        <HashRouter>
            <Navbar />
            <Routes>
                <Route exact path='/vat/uae' element={<Country />} />
                <Route exact path='/vat/ksa' element={<Country />} />
                <Route exact path='/vat/egypt' element={<Country />} />
            </Routes>
            <Footer />
        </HashRouter>
    )
}
export default VatApp