import { ThreePulseCircles } from "../../../Animation/Animation"
import { utils, writeFileXLSX } from 'xlsx'

const RtoStatus = (props) => {
    const { rto, status } = props
    if (status === "INIT") return <ThreePulseCircles />
    else if (rto?.validTill) return <span className={"text-success"}>{rto.validTill}</span>
    else return <span className="text-danger">{rto?.error}</span>
}

const RtoEntry = (props) => {
    const { rto } = props
    return (
        <tr key={rto.ewaybillNo}>
            <td>{rto.ewaybillNo}</td>
            <td className='text-success'>
                {rto.rtoEWaybill}
            </td>
            <td colSpan={2}>
                <RtoStatus {...props} />
            </td>
        </tr>
    )
}

const Table = ({ requests }) => {
    const response = requests[0]?.response
    const isExcelDownloadDisabled =
        requests[0]?.status === "INIT" ||
        requests[0]?.status === undefined

    const getRTORep = (ewb) => response?.rtoEWaybills?.find(r => r.ewaybillNo === ewb) || { ewaybillNo: ewb }
    const responses = response?.ewaybillNos?.map(e => getRTORep(e)) || []

    const handleDownloadExcel = () => {
        const newArray = responses.map(({ ewaybillNo, rtoEWaybill, validTill, error }) => ({
            EWB: ewaybillNo,
            "RTO EWB": rtoEWaybill,
            "Valid Till": validTill,
            Error: error
        }))
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        const date = new Date().toLocaleString()
        writeFileXLSX(wb, `ReturnToOrigin${date}.xlsx`)
    }
    
    return (
        <table className="table table-sm">
            <thead className="table-secondary">
                <tr className="text-uppercase">
                    <th>Forward EWB</th>
                    <th>RTO Ewaybill</th>
                    <th>Validity/Error</th>
                    <th>
                        <button
                            className="btn btn-success btn-sm"
                            onClick={handleDownloadExcel}
                            disabled={isExcelDownloadDisabled}
                            type="button">
                            Excel Download
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    responses.map(r => {
                        return (
                            <RtoEntry rto={r} status={response?.status} />
                        )
                    })
                }
            </tbody>
        </table>
    )
}
export default Table