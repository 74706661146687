import classNames from "classnames";

export const PrimaryNormalButton = ({children, ...props}) => {
    return (
        <button className="btn btn-primary" type="button" {...props}>
            {children}
        </button>
    )
}

export const PrimarySubmitButton = ({children, ...props}) => {
    return (
        <button className="btn btn-primary" type="submit" {...props}>
            {children}
        </button>
    )
}

export const DownloadButton = ({children, ...props}) => {
    let ec = Object.fromEntries(
        (props['extraClass'] || '').split(" ").filter(x => x.length > 0).map(x => ([x, true]))
    )
    let btnClass = classNames({"btn": true, "btn-info": true, ...ec })
    return (
        <button className={btnClass} type="button" {...props}>
            {children}
        </button>
    )
}

