import React, { useState, useContext } from "react"; // eslint-disable-line import/first
import { utils, writeFileXLSX } from 'xlsx';
import { Actions } from "../../../Reducer/reducer";
import { CredentialsContext } from "../../../API/CredentialsContext";
import Table from "./Table";
import { DownloadButton } from "../../../Common/Button";

const CheckGSTINS = () => {
    const [gstin, setGstin] = useState([])
    const [data, setData] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const isCheckDealersButtonDisabled =
        gstin.length === 0 ||
        state.loading

    const handleGstinChange = (event) => {
        setGstin(event.target.value.split("\n").map(x => x.toUpperCase()));
    }

    const handleCheckDealers = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            setGstin(gstin.join(","))
            const response = await fetch(`https://prod-1.e-waybill.in/api/gstin/bulk-validate?gstin=${gstin}`);
            if (response.ok) {
                const responseData = await response.json();
                setData(responseData)
            }
        } catch (error) {
            console.error('Error in checking gstin of EWB:', error);
        } finally {
            dispatch({ type: Actions.HideLoading });
        }
    }

    const downloadExcel = () => {
        const newArray = data.map(({ gstNumber, name, address, address2, state, pinCode, valid }) => ({
            gstNumber,
            name,
            address: address2 === "" ? address : address + address2,
            state: state === "NOT_AVAILABLE" ? "" : state,
            pinCode,
            valid: valid ? "VALID" : "IN-VALID"
        }));
        let dt = new Date();
        dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
        const ws = utils.json_to_sheet(newArray);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `Validate_${dt}.xlsx`);
    };

    return (
        <div className="py-2">
            <div className="row my-5">
                <form className="col-lg-3" onSubmit={handleCheckDealers}>
                    <h4>Check GSTIN</h4>
                    <textarea
                        className="form-control mb-2"
                        style={{ height: "400px" }}
                        name="gstin"
                        id="gstin"
                        placeholder="GSTIN One Per Line"
                        onChange={handleGstinChange}
                    />
                    <button
                        className="btn btn-primary mb-5"
                        type="submit"
                        disabled={isCheckDealersButtonDisabled}
                    >
                        Check Dealers
                    </button>
                </form>
                <div className="col-lg-9">
                    <div className="d-flex justify-content-end mb-3">
                        <DownloadButton
                            onClick={downloadExcel}>
                            Download Excel
                        </DownloadButton>
                    </div>
                    <div className="overflow-x-auto mb-3">
                        <Table data={data} />
                    </div>
                </div>
            </div>
        </div >
    )
}
export default CheckGSTINS;