import { useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useIndexedDB } from "react-indexed-db-hook"
import IndianStates from '../../../Data/IndianStates.json'
import { CredentialsContext } from "../../../API/CredentialsContext"
import MainTable from "./MainTable"
import SubTable from "./SubTable"
import { Actions } from "../../../Reducer/reducer"
import Wrapper from "../../../Common/Wrapper"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"

const Update = () => {
    const [partBState, setPartBState] = useState({
        credential: {},
        transportType: "ROAD",
        vehicleNo: "",
        masterAWB: "",
        updateType: "TRANSHIPMENT",
        location: "Bangalore",
        stateType: "KA",
        waybillNo: "",
        pageUrl: window.location.hash
    })
    const [requests, setRequests] = useState([])
    const [requestsDB, setRequestsDB] = useState([])
    const [requestInDetail, setRequestInDetail] = useState()
    const pageUrl = window.location.hash
    const { getAll, add, deleteRecord, update, clear } = useIndexedDB("RequestsForPartB")
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isButtonDisabled =
        Object.keys(partBState.credential).length === 0 ||
        partBState.transportType === "" ||
        partBState.updateType === "" ||
        partBState.location === "" ||
        partBState.stateType === "" ||
        partBState.waybillNo === "" ||
        (
            partBState.transportType === "ROAD" &&
            partBState.vehicleNo === ""
        ) ||
        (
            (
                partBState.transportType === "AIR" ||
                partBState.transportType === "RAIL"
            ) &&
            partBState.masterAWB === ""
        )

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPartBState(prevState => (
                {
                    ...prevState,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setPartBState(prevState => (
                {
                    ...prevState,
                    [name]: value
                }
            ))
        }
    }

    const handleUpdateEWB = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                ewaybillNos: partBState.waybillNo.split("\n"),
                modeOfTransport: partBState.transportType,
                vehicleNo: partBState.vehicleNo,
                masterAwb: partBState.masterAWB,
                transhipmentLocation: partBState.location,
                consolidate: pageUrl === '#/part-b/consolidate',
                updateReason: partBState.updateType,
                state: partBState.stateType,
                credentials: {
                    userName: partBState.credential.userName,
                    password: partBState.credential.password,
                    gstNumber: partBState.credential.gstNumber,
                    loginType: "TR"
                }
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://api.e-waybill.in/api/e-waybill/update',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId }),
                })
            if (response.ok) {
                const data = await response.json()
                const requestTime = new Date().getTime()
                const requestTimeStr = new Date().toLocaleString('en-IN')
                await add({
                    requestId,
                    requestTime,
                    requestTimeStr,
                    status: data.status,
                    response: data,
                    request: payload,
                    checkCount: 0
                })
                setRequests(r => (
                    [...r, {
                        requestId,
                        requestTime,
                        requestTimeStr,
                        status: data.status,
                        response: data,
                        request: payload,
                        checkCount: 0
                    }]
                ))
            } else {
                console.error("Error in getting response from update/consolidate api")
            }
        }
        catch (error) { console.error('Error in generating ewb:', error) }
    }

    const showReqInDetail = (req) => {
        setRequestInDetail(req)
    }

    const handleDeleteEachReqFromDB = (req) => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            deleteRecord(req.requestId).then((event) => {
                alert(`${req.requestId} was deleted`)
            })
            setRequests(r => r.filter(x => x.requestId !== req.requestId))
        }
    }

    const handleDeleteAllReqFromDB = () => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            clear().then(() => {
                alert("Deleted all requests")
            })
        }
    }

    useEffect(() => {
        getAll().then(r => {
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequests(r)
        })
    }, [getAll])

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://api.e-waybill.in/api/e-waybill/update-status?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'COMPLETED') {
                        update({ ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        update({ ...r, status: data.status, response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            }
            catch (error) { console.error('Error in checking status of updating EWB:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        (async () => {
            const r = await getAll()
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequestsDB(r)
        })()

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch, getAll, update])

    return (
        <Wrapper>
            <form className="col-lg-3 m-3" onSubmit={handleUpdateEWB}>
                <h4>{pageUrl === '#/part-b/update' ? "Update Part-B" : "Consolidated EWB"}</h4>
                <FormFieldWrapper>
                    <label htmlFor='credential' className="mb-2">Credential</label>
                    <select
                        className="form-select"
                        id='credential'
                        name='credential'
                        onChange={handleState}
                    >
                        <option>Select</option>
                        {
                            users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.userName} - {user.gstNumber}</option>
                                )
                            })
                        }
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="transportType" className="mb-2">Mode Of Transport</label>
                    <select
                        className="form-select"
                        id='transportType'
                        name="transportType"
                        onChange={handleState}
                    >
                        <option value="ROAD">Road</option>
                        <option value="AIR">Air</option>
                        <option value="RAIL">Rail</option>
                    </select>
                </FormFieldWrapper>                 {
                    partBState.transportType === "ROAD" ?
                        <FormFieldWrapper>
                            <label htmlFor="vehicleNo" className="mb-2">Vehicle Number</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle No"
                                id="vehicleNo"
                                name="vehicleNo"
                                onChange={handleState} />
                        </FormFieldWrapper> :
                        <FormFieldWrapper>
                            <label htmlFor="masterAWB" className="mb-2">Master AWB</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Master AWB"
                                id="masterAWB"
                                name="masterAWB"
                                onChange={handleState}
                            />
                        </FormFieldWrapper>}
                <FormFieldWrapper>
                    <label htmlFor="updateType" className="mb-2">Reason of Update</label>
                    <select
                        className="form-select"
                        name="updateType"
                        id="updateType"
                        onChange={handleState}
                    >
                        <option value="TRANSHIPMENT">TRANSHIPMENT</option>
                        <option value="BREAKDOWN">BREAKDOWN</option>
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="location" className="mb-2">Location</label>
                    <input
                        className="form-control"
                        placeholder="Location"
                        id="location"
                        name="location"
                        onChange={handleState}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="stateType" className="mb-2">State of Dispatch</label>
                    <select
                        className="form-select"
                        name="stateType"
                        id="stateType"
                        onChange={handleState}
                    >
                        {
                            IndianStates.map((state, index) => {
                                return (
                                    <option key={index} value={state.value}>{state.label}</option>
                                )
                            })
                        }
                    </select>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label className="mb-2" htmlFor="waybillNo">Waybill Nos</label>
                    <textarea
                        className="form-control"
                        style={{ height: "200px" }}
                        id="waybillNo"
                        name="waybillNo"
                        placeholder="Enter Waybill Nos, One Per Line"
                        onChange={handleState}
                    />
                </FormFieldWrapper>                 <button
                    className="btn btn-primary mb-5"
                    disabled={isButtonDisabled}
                    type="submit"
                >
                    {
                        pageUrl === '#/part-b/update' ?
                            "Update Part-B" :
                            "Generate Consolidated EWB"
                    }
                </button>
            </form>
            <div className="col-lg-7 m-5">
                <div className="row">
                    <h4>Requests</h4>
                    <MainTable
                        pageUrl={pageUrl}
                        handleDeleteEachReqFromDB={handleDeleteEachReqFromDB}
                        handleDeleteAllReqFromDB={handleDeleteAllReqFromDB}
                        requestsDB={requestsDB}
                        showReqInDetail={showReqInDetail}
                    />
                </div>
                {
                    requestInDetail !== undefined &&
                    <SubTable requestInDetail={requestInDetail} />
                }
            </div>
        </Wrapper>
    )
}
export default Update