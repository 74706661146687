import './App.css'
import { initDB } from 'react-indexed-db-hook'
import { DBConfiguration } from './DBConfig'
import VatApp from './VAT/VatApp'
import EwbApp from './EwbApp'
import OtpApp from './OTP/OtpApp'
initDB(DBConfiguration)

function App() {

    const hostname = window.location.hostname

    if (hostname === 'localhost') {
        const url = (window.location.hash).split("/")[1]
        if (url === "vat") {
            return <VatApp />
        } else if (url === "otp" || url === "login") {
            return <OtpApp />
        } else {
            return <EwbApp />
        }
    } else {
        const ewaybill = "app.e-waybill.in"
        const vat = "validate-vat.e-waybill.in"
        const otp = "otp.e-waybill.in"
        if (hostname === vat) {
            return <VatApp />
        } else if (hostname === ewaybill) {
            return <EwbApp />
        } else if (hostname === otp) {
            return <OtpApp />
        }
        else {
            return "Page not found"
        }
    }
}

export default App
