import IndianStates from '../../../Data/IndianStates.json'

const TopTable = ({ modalState, handleModalState }) => {
    return (
        <table className="table table-bordered">
            <tbody>
                <tr>
                    <th className="text-uppercase">
                        <label htmlFor="transhipmentLocation">
                            Transhipment Location
                        </label>
                    </th>
                    <td>
                        <input
                            className="form-control"
                            id="transhipmentLocation"
                            value={modalState.transhipmentLocation}
                            name="transhipmentLocation"
                            onChange={handleModalState}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="text-uppercase">
                        <label htmlFor="transhipmentState">
                            Transhipment State
                        </label>
                    </th>
                    <td>
                        <select className="form-select"
                            value={modalState.transhipmentState}
                            name="transhipmentState"
                            id="transhipmentState"
                            onChange={handleModalState}>
                            <option>Select</option>
                            {
                                IndianStates.map((state, index) => {
                                    return (
                                        <option key={index} value={state.value}>{state.label}</option>
                                    )
                                })
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <th className="text-uppercase">
                        <label htmlFor="vehicleNo">Vehicle No</label>
                    </th>
                    <td>
                        <input
                            className="form-control"
                            id="vehicleNo"
                            value={modalState.vehicleNo}
                            name="vehicleNo"
                            onChange={handleModalState}
                            maxLength="10"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
export default TopTable