import { useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons"
import { useIndexedDB } from "react-indexed-db-hook"
import PartBMultiVehicleUpdateTable from "./MainTable"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { Actions } from "../../../Reducer/reducer"
import { ThreePulseCircles } from "../../../Animation/Animation"
import FormFieldWrapper from "../../../Common/FormFieldWrapper"

const MVU = () => {
    const [updateState, setUpdateState] = useState({
        ewaybillNo: "",
        credential: {},
        quantity: 0,
        unitOfMeasurement: "",
        fromLocation: "",
        toLocation: ""
    })
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const [requests, setRequests] = useState([])
    const [requestsDB, setRequestsDB] = useState([])
    const [modalState, setModalState] = useState({
        vehicleNo: "",
        qty: 0
    })
    const { getAll, add, update, clear } = useIndexedDB("RequestsForMVUTable")
    const isCreateMultiVehicleButtonDisabled =
        updateState.ewaybillNo === "" ||
        updateState.quantity === "" ||
        updateState.quantity === 0 ||
        updateState.unitOfMeasurement === "" ||
        updateState.fromLocation === "" ||
        updateState.toLocation === "" ||
        Object.keys(updateState.credential).length === 0 ||
        state.loading ||
        requestsDB.some(request => request.response.status !== "COMPLETED")
    const isModalUpdateButtonDisabled =
        modalState.qty === 0 ||
        modalState.qty === "" ||
        modalState.vehicleNo === "" ||
        modalState.vehicleNo.length < 9

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setUpdateState(prev => ({
                ...prev,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setUpdateState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleModalState = (event) => {
        const { name, value } = event.target
        setModalState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleClearModalState = () => {
        handleModalState({ target: { name: 'qty', value: '' } })
        handleModalState({ target: { name: 'vehicleNo', value: '' } })
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleCreateMultiVehicleApi(event)
        }
    }

    const handleCreateMultiVehicleApi = async (event) => {
        try {
            event.preventDefault()
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                createLeg: true,
                credentials: {
                    userName: updateState.credential.userName,
                    password: updateState.credential.password,
                    gstNumber: updateState.credential.gstNumber
                },
                ewb: updateState.ewaybillNo,
                qty: updateState.quantity,
                from: updateState.fromLocation,
                to: updateState.toLocation,
                uom: updateState.unitOfMeasurement
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/multi-update',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId }),
                })

            if (response.ok) {
                const data = await response.json()
                const requestTime = new Date().getTime()
                const requestTimeStr = new Date().toLocaleString('en-IN')
                await add({
                    requestId,
                    requestTime,
                    requestTimeStr,
                    status: data.status,
                    response: data,
                    request: payload,
                    total: 0,
                    checkCount: 0
                })
                setRequests(r => ([...r, {
                    requestId,
                    requestTime,
                    requestTimeStr,
                    status: data.status,
                    response: data,
                    request: payload,
                    total: 0,
                    checkCount: 0
                }]))
            } else {
                console.error('multi-update failed')
            }
        }
        catch (error) { console.error('Error in getting response from multi vehicle update api:', error) }
    }

    const handleUpdateVehicleApi = async (request, requestId) => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: request.request.credentials.userName,
                    password: request.request.credentials.password,
                    gstNumber: request.request.credentials.gstNumber
                },
                ewb: request.request.ewb,
                qty: request.request.qty,
                from: request.request.from,
                to: request.request.to,
                uom: request.request.uom,
                errorMessage: request.response.errorMessage,
                updateStatus: {},
                loginStatus: false,
                status: "COMPLETED",
                vehicles: [
                    {
                        vehicle: modalState.vehicleNo,
                        qty: modalState.qty
                    }
                ],
                requestId: requestId + ":" + modalState.vehicleNo
            }
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/multi-update?sync=true',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                })
            if (response.ok) {
                const singleVehResponse = await response.json()
                setRequests(existingReqs => existingReqs.map(existingReq => {
                    if (existingReq.requestId === requestId) {
                        const { response } = existingReq
                        const { vehicles } = response
                        const newResponse = { ...response, vehicles: [...vehicles, ...singleVehResponse.vehicles] }
                        update({
                            ...existingReq,
                            response: newResponse,
                            total: newResponse.vehicles.map(v => v.qty)?.reduce((a, b) => a + b, 0)
                        })
                        return {
                            ...existingReq,
                            response: newResponse,
                            total: newResponse.vehicles.map(v => v.qty)?.reduce((a, b) => a + b, 0)
                        }
                    } else {
                        return existingReq
                    }
                }))
            }
        }
        catch (error) { console.error('Error in getting response from update vehicle api:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const handleDeleteAllReqFromDB = () => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            clear().then(() => {
                alert("Deleted all requests")
            })
        }
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://prod-1.e-waybill.in/api/e-waybill/multi-update?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'COMPLETED') {
                        await update({ ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        await update({ ...r, status: data.status, response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: data.status, response: data, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            }
            catch (error) { console.error('Error in checking status of multi vehicle update api:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        (async () => {
            const r = await getAll()
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequestsDB(r)
        })()

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch, getAll, update])

    useEffect(() => {
        getAll().then(r => {
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequests(r)
        })
    }, [getAll])

    return (
        <form className="py-2" onSubmit={handleCreateMultiVehicleApi}>
            <div className="row">
                <div className="col-lg-3 m-3">
                    <h4>Update Multi-Vehicle</h4>
                    <FormFieldWrapper>
                        <label htmlFor="ewaybillNo" className="mb-2">EWaybill No</label>
                        <input
                            className="form-control"
                            type="text"
                            name="ewaybillNo"
                            id="ewaybillNo"
                            onChange={handleState}
                            maxLength="12"
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="credential" className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })}
                        </select>
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="quantity" className="mb-2">Qty</label>
                        <input
                            className="form-control"
                            type="number"
                            name="quantity"
                            id="quantity"
                            onChange={handleState}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="unitOfMeasurement" className="mb-2">UOM</label>
                        <select
                            className="form-select"
                            name="unitOfMeasurement"
                            id="unitOfMeasurement"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            <option value="TON">TON</option>
                            <option value="NOS">NOS</option>
                        </select>
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="fromLocation" className="mb-2">From Location</label>
                        <input
                            className="form-control"
                            type="text"
                            name="fromLocation"
                            id="fromLocation"
                            onChange={handleState}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="toLocation" className="mb-2">To Location</label>
                        <input
                            className="form-control"
                            type="text"
                            name="toLocation"
                            id="toLocation"
                            onChange={handleState}
                            onKeyDown={handleKeyDown}
                        />
                    </FormFieldWrapper>
                    {
                        requestsDB.some(request => request.response.status !== "COMPLETED") ?
                            <ThreePulseCircles /> :
                            <button
                                type="submit"
                                className="btn btn-success mb-5"
                                disabled={isCreateMultiVehicleButtonDisabled}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} /> Create Multi Vehicle
                            </button>
                    }
                </div>
                <div className="col-lg-7 m-5">
                    <div className="row">
                        <div>
                            <h4>Existing Multi Vehicle Updates</h4>
                        </div>
                        <PartBMultiVehicleUpdateTable
                            handleDeleteAllReqFromDB={handleDeleteAllReqFromDB}
                            requestsDB={requestsDB}
                            handleModalState={handleModalState}
                            handleUpdateVehicleApi={handleUpdateVehicleApi}
                            modalState={modalState}
                            handleClearModalState={handleClearModalState}
                            isModalUpdateButtonDisabled={isModalUpdateButtonDisabled}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}
export default MVU