import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { jwtDecode } from "jwt-decode"
import { useGoogleLogin } from "@react-oauth/google"
import { getUserCredentials } from "../../API/GetUserCreds"
import LoginUI from "../../Common/Login"

const Login = () => {

    const [loginState, setLoginState] = useState({
        userName: "",
        password: "",
        errorMessage: '',
        loading: false
    })
    const navigate = useNavigate()
    const isButtonDisabled = loginState.userName === "" || loginState.password === ""

    const handleState = (event) => {
        const { name, value } = event.target
        setLoginState(state => ({
            ...state,
            [name]: value
        }))
    }

    const handleLoginWithCredentials = async (event) => {
        event.preventDefault()
        try {
            setLoginState((prev) => ({ ...prev, loading: true }))

            const payload = {
                userName: loginState.userName,
                password: loginState.password
            }
            const response = await fetch('https://app.e-waybill.in/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })

            const data = await response.json()

            if (data.authToken) {
                localStorage.setItem("authToken-otp", data.authToken)
                navigate('/otp')
            }
            if (data.title === "Invalid Login Credentials") {
                setLoginState((prev) => ({ ...prev, errorMessage: "Invalid Login Credentials" }))
            }
            setLoginState((prev) => ({ ...prev, loading: false }))
        } catch (error) {
            console.error('Error:', error)
            setLoginState((prev) => ({ ...prev, loading: false }))
        }
    }

    const getAuthTokenForCode = async (code) => {
        const redirect_uri = window.location.protocol + "//" + window.location.host
        const response = await fetch("https://app.e-waybill.in/auth/google-login", {
            method: "POST",
            body: JSON.stringify({ code, redirect_uri, redirectUri: redirect_uri })
        })
        if (response.ok) {
            const b = await response.json()
            const decode = jwtDecode(b["token"])
            console.log('from Token', b["token"], "got ", decode)
            return decode["sub"]
        }
        return null
    }

    function handleGoogleLoginFailure() {
        setLoginState(prev => ({ ...prev, errorMessage: 'Login Failed from Google' }))
    }

    const handleGoogleLogin = useGoogleLogin({
        scope: "email profile",
        onSuccess: codeResponse => {
            getAuthTokenForCode(codeResponse.code).then(authToken => {
                if (authToken) {
                    localStorage.setItem("authToken-otp", authToken)
                    getUserCredentials(authToken)
                        .then(creds => {
                            navigate('/otp')
                            console.log("cred", creds)
                        })
                } else {
                    handleGoogleLoginFailure()
                }
            }, () => {
                handleGoogleLoginFailure()
            })
        },
        flow: 'auth-code',
    })

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleLoginWithCredentials(event)
        }
    }

    return (
        <LoginUI
            loginState={loginState}
            isButtonDisabled={isButtonDisabled}
            handleGoogleLogin={handleGoogleLogin}
            handleKeyDown={handleKeyDown}
            handleState={handleState}
            handleLoginWithCredentials={handleLoginWithCredentials} />
    )
}
export default Login