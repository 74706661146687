import { HashRouter, Route, Routes } from "react-router-dom"
import Navbar from "./Common/Navbar"
import Login from "./Pages/Login"
import Otp from "./Pages/Otp"
import { RequireAuth } from "../RequireAuth"
import Footer from "../Common/Footer"

const OtpApp = () => {
    return (
        <HashRouter>
            <Navbar />
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/otp" element={
                    <RequireAuth page="otp">
                        <Otp />
                    </RequireAuth>}
                />
            </Routes>
            <Footer />
        </HashRouter>
    )
}
export default OtpApp