import {useContext, useState} from "react";
import {CredentialsContext} from "../../../API/CredentialsContext";
import {Actions} from "../../../Reducer/reducer";
import {read, utils, writeFileXLSX} from "xlsx";
import {DownloadButton} from "../../../Common/Button";
import Table from "./Table";

const CheckDistance = () => {
    const [gstin, setGstin] = useState([])
    const [data, setData] = useState([])
    const {state, dispatch} = useContext(CredentialsContext)
    const isCheckReturnButtonDisabled =
        gstin.length === 0 ||
        state.loading

    const handleGstinChange = (event) => {
        setGstin(event.target.value)
    }

    const handleCheckReturn = async (e) => {
        e.preventDefault()
        let map = gstin.split("\n").map(eachGstin => eachGstin.split(","))
            .filter(x => x.length === 2)
            .filter(x => x[0].length === 6 && x[1].length === 6)
            .map(x => ({
                pincode1: x[0],
                pincode2: x[1],
                distance: '',
                pending: true
            }));
        setData(map)

        dispatch({type: Actions.ShowLoading})
        setData(await Promise.all(
            map.map(async ({pincode1, pincode2}) => {
                try {
                    const response = await fetch(`https://prod-1.e-waybill.in/api/pincode/distance?pincode1=${pincode1}&pincode2=${pincode2}`)
                    if (response.ok) {
                        return {...(await response.json()), pending: false}
                    } else {
                        return {pincode1: pincode1, pincode2: pincode2, distance: 'NA', pending: false}
                    }
                } catch (e) {
                    console.error('error in searching for pincode', e)
                    return {pincode1: pincode1, pincode2: pincode2, distance: 'NA', pending: false}
                }
            })
        ))
        dispatch({type: Actions.HideLoading})
    }

    const downloadExcel = () => {

        let dt = new Date()
        dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()
        const ws = utils.json_to_sheet(data)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `Distance_${dt}.xlsx`)
    }
    const downloadSampleExcel = () => {

        let dt = new Date()
        dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()
        const ws = utils.json_to_sheet([
            {pincode1: "560078", pincode2: "600025"},
            {pincode1: "638402", pincode2: "560082"}
        ])
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `Distance_Sample_${dt}.xlsx`)
    }

    async function handleFileAsync(e) {
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const wb = read(data)
        const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
        let sheetToJson = utils.sheet_to_json(ws);
        setGstin(sheetToJson.map(r => `${r.pincode1},${r.pincode2}`).join("\n"))
    }

    return (
        <div className="py-2">
            <h4 className={"mt-3"}>Check Pin-To-Pin Distance</h4>
            <div className="row my-3">
                <form className="col-lg-3" onSubmit={handleCheckReturn}>
                    <div className="mb-3">
                        <label htmlFor="gstin" className="form-label">Enter PinCode Combination</label>
                        <textarea
                            className="form-control mb-2"
                            style={{height: "400px"}}
                            name="gstin"
                            id="gstin"
                            value={gstin}
                            placeholder="from-pincode,to-pincode
                        from-pincode2,to-pincode2"
                            onChange={handleGstinChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Select Excel File</label>
                        <input type="file" id="formFile" className={"my-3 form-control"}
                               onChange={handleFileAsync}/>
                    </div>


                    <button
                        className="btn btn-primary mb-5"
                        type="submit"
                        disabled={isCheckReturnButtonDisabled}
                    >
                        Check Distance
                    </button>
                </form>
                <div className="col-lg-9">
                    <div className="d-flex justify-content-end mb-3">
                        <DownloadButton
                            extraClass={"btn-danger me-1"}
                            onClick={downloadSampleExcel}>
                            Download Sample Excel
                        </DownloadButton>
                        <DownloadButton
                            onClick={downloadExcel}>
                            Download Excel
                        </DownloadButton>
                    </div>
                    <div className="overflow-x-auto mb-3">
                        <Table data={data} gstin={gstin}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckDistance