import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"

const Table = ({ creds, handleSelectCredential }) => {
    return (
        <div className="col-lg-7 m-5">
            <h4 className="col-3">Existing Credentials</h4>
            <div className="overflow-x-auto mb-3">
                <table className="table table-sm">
                    <thead>
                        <tr className="text-uppercase">
                            <th>User Name</th>
                            <th>Password</th>
                            <th>GST Number</th>
                            <th>OTP</th>
                            <th>&nbsp</th>
                            <th>&nbsp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {creds.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td>{user.userName}</td>
                                    <td>{user.password}</td>
                                    <td>{user.gstNumber}</td>
                                    <td>
                                        {
                                            user.otp &&
                                            <>
                                                <div>OTP</div>
                                                <div>Valid For</div>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {
                                            user.otp &&
                                            <>
                                                <div>{user.otp}</div>
                                                <div>{user.seconds} Seconds</div>
                                            </>
                                        }
                                    </td>
                                    <td
                                        className="text-success"
                                        onClick={handleSelectCredential.bind(this, index)}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Table