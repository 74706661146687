import ReactPaginate from "react-paginate"
import { useState } from "react"
import { PrimaryNormalButton } from "../../../Common/Button"

const ExpringEWBTable = ({ ewbsToExtend, requests, expiringEWBState, handleEWBCheckbox }) => {

    const ewbDetails = requests[0]?.response?.ewaybillDetails
    const PER_PAGE = 4
    const [currentPage, setCurrentPage] = useState(0)
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }
    const offset = currentPage * PER_PAGE
    const pageCount = Math.ceil(ewbDetails?.length / PER_PAGE)

    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-sm">
                <thead className="table-secondary">
                    <tr className="text-uppercase">
                        <th>Ewaybill No</th>
                        <th>Consignor GST</th>
                        <th>Consignor Address</th>
                        <th>Consignor Pin</th>
                        <th>Consignee GST</th>
                        <th>Consignee Address</th>
                        <th>Consignee Pin</th>
                        <th>Expiring On</th>
                        <th>
                            <PrimaryNormalButton
                                disabled={ewbsToExtend?.length === 0}
                                data-bs-toggle="modal"
                                data-bs-target="#extendEWBModal">
                                Extend
                            </PrimaryNormalButton>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (
                            ewbDetails?.length === 0 &&
                            requests[0]?.response?.ewaybills?.length === 0 &&
                            !requests[0]?.response?.loginStatus
                        ) ?
                            (
                                <tr>
                                    <td colSpan={9} className="text-danger text-center">
                                        No EWaybill Details Found For This Credential !
                                    </td>
                                </tr>
                            ) :
                            (
                                ewbDetails?.filter(item => item.ewaybill.includes(expiringEWBState.keyword))
                                    .slice(offset, offset + PER_PAGE)
                                    .map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.ewaybill}</td>
                                                <td>{item.consignorGst}</td>
                                                <td>{item.consignorAddr}</td>
                                                <td>{item.consignorPin}</td>
                                                <td>{item.consigneeGst}</td>
                                                <td>{item.consigneeAddr}</td>
                                                <td>{item.consigneePin}</td>
                                                <td>{item.validTill}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id={item.ewaybill}
                                                        checked={ewbsToExtend.indexOf(item.ewaybill) > -1}
                                                        onChange={() => handleEWBCheckbox(item)} /> <label
                                                            htmlFor={item.ewaybill}>
                                                        Select
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    })
                            )
                    }
                </tbody>
            </table>
            {
                ewbDetails?.length > PER_PAGE &&
                <div className="mb-5">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active primary"}
                    />
                </div>
            }
        </div>
    )
}
export default ExpringEWBTable